<template>
  <el-dialog
      title="智能处理"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
    <el-form size="small" ref="inputForm" :model="inputForm" :rules="rules" label-width="140px">
      <el-form-item label="需要替换的著录" prop="oldCataloging">
        <el-select v-model="inputForm.oldCataloging" clearable
                   @change="selectOldCataloging"
                   placeholder="请选择需要替换的著录（固定字段+元数据字段）" style="width: 100%;">
          <el-option
              v-for="item in allCatalogingList"
              :key="item.code"
              :label="item.forName ? item.forName : item.basicName"
              :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="Object.keys(selectData).length" label="需要替换的内容"
                    prop="oldCount">
        <!--字符-->
        <el-input :type="selectData.maxLength && selectData.maxLength > 100 ? 'textarea': ''"
                  v-model.trim="inputForm.oldCount" :maxlength="selectData.maxLength || 300"
                  :placeholder="selectData.forName ? '请输入' + selectData.forName : '请输入' + selectData.basicName"
                  clearable
                  v-if="selectData.archivesBasicTypeCode == 'text' && !selectData.archivesBasicMultiselectList.length"></el-input>
        <!--数值、浮点数-->
        <el-input v-model.trim="inputForm.oldCount" maxlength="11"
                  @input="getNumType(selectData)"
                  @blur="verifyNum(selectData)"
                  :placeholder="selectData.forName ? '请输入' + selectData.forName : '请输入' + selectData.basicName"
                  clearable
                  v-if="selectData.archivesBasicTypeCode == 'integer' || selectData.archivesBasicTypeCode == 'double'"></el-input>
        <!--下拉选择-->
        <el-select class="w100i" v-model="inputForm.oldCount"
                   :multiple="selectData.archivesBasicMultiselectList[0].selectBy == 1"
                   collapse-tags
                   :placeholder="selectData.forName ? '请选择' + selectData.forName : '请选择' + selectData.basicName"
                   clearable
                   v-if="selectData.archivesBasicMultiselectList.length">
          <el-option v-for="(v,i) in selectData.archivesBasicMultiselectList" :key="i"
                     :label="selectData.fixedField ? v.label : v.archivesBasicValue"
                     :value="selectData.fixedField ? v.value :v.archivesBasicValue"></el-option>
        </el-select>
        <!--时间-->
        <el-date-picker class="w100i"
                        v-model="inputForm.oldCount"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" clearable placeholder="请选择"
                        v-if="selectData.archivesBasicTypeCode == 'date' && selectData.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
        </el-date-picker>
        <el-date-picker class="w100i"
                        v-model="inputForm.oldCount" value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        type="date" clearable placeholder="请选择"
                        v-if="selectData.archivesBasicTypeCode == 'date' && selectData.dataFormat == 'yyyy-MM-dd'">
        </el-date-picker>
        <el-date-picker class="w100i"
                        v-model="inputForm.oldCount" value-format="yyyyMMdd"
                        format="yyyyMMdd"
                        type="date" clearable placeholder="请选择"
                        v-if="selectData.archivesBasicTypeCode == 'date' && selectData.dataFormat == 'yyyyMMdd' || selectData.archivesBasicTypeCode == 'date' && !selectData.dataFormat">
        </el-date-picker>
      </el-form-item>

      <el-form-item v-if="Object.keys(selectData).length" label="需要替换成的内容"
                    prop="newCount">
        <!--字符-->
        <el-input :type="selectData.maxLength && selectData.maxLength > 100 ? 'textarea': ''"
                  v-model.trim="inputForm.newCount" :maxlength="selectData.maxLength || 300"
                  :placeholder="selectData.forName ? '请输入' + selectData.forName : '请输入' + selectData.basicName"
                  clearable
                  v-if="selectData.archivesBasicTypeCode == 'text' && !selectData.archivesBasicMultiselectList.length"></el-input>
        <!--数值、浮点数-->
        <el-input v-model.trim="inputForm.newCount" maxlength="11"
                  @input="getNumType(selectData,1)"
                  @blur="verifyNum(selectData,1)"
                  :placeholder="selectData.forName ? '请输入' + selectData.forName : '请输入' + selectData.basicName"
                  clearable
                  v-if="selectData.archivesBasicTypeCode == 'integer' || selectData.archivesBasicTypeCode == 'double'"></el-input>
        <!--下拉选择-->
        <el-select class="w100i" v-model="inputForm.newCount"
                   :multiple="selectData.archivesBasicMultiselectList[0].selectBy == 1"
                   collapse-tags
                   :placeholder="selectData.forName ? '请选择' + selectData.forName : '请选择' + selectData.basicName"
                   clearable
                   v-if="selectData.archivesBasicMultiselectList.length">
          <el-option v-for="(v,i) in selectData.archivesBasicMultiselectList" :key="i"
                     :label="selectData.fixedField ? v.label : v.archivesBasicValue"
                     :value="selectData.fixedField ? v.value :v.archivesBasicValue"></el-option>
        </el-select>
        <!--时间-->
        <el-date-picker class="w100i"
                        v-model="inputForm.newCount" value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetime" clearable placeholder="请选择"
                        v-if="selectData.archivesBasicTypeCode == 'date' && selectData.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
        </el-date-picker>
        <el-date-picker class="w100i"
                        v-model="inputForm.newCount" value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd"
                        type="date" clearable placeholder="请选择"
                        v-if="selectData.archivesBasicTypeCode == 'date' && selectData.dataFormat == 'yyyy-MM-dd'">
        </el-date-picker>
        <el-date-picker class="w100i"
                        v-model="inputForm.newCount" value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyyMMdd"
                        type="date" clearable placeholder="请选择"
                        v-if="selectData.archivesBasicTypeCode == 'date' && selectData.dataFormat == 'yyyyMMdd' || selectData.archivesBasicTypeCode == 'date' && !selectData.dataFormat">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="text_center" style="margin-top: 20px">
      <el-button size="small" @click="handleClose()">取消</el-button>
      <el-button size="small" type="primary" @click="sureData()" v-noMoreClick>替换</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "intelligentColl",
  data() {
    return {
      dialogVisible: false,
      inputForm: {
        oldCataloging: '',
        oldCount: '',
        newCount: '',
      },
      rules: {
        oldCataloging: [
          {required: true, message: '请选择需要替换的著录', trigger: 'change'}
        ],
      },
      selectData: {}, //要修改的著录
      allCatalogingList: [], //所有著录
      dataListSelect: [], //要修改著录的数据

      typeList: [],
      identifyList: [
        {
          label: '是',
          value: '0',
        },
        {
          label: '否',
          value: '1',
        },
      ],
      leaveList: [],
      integrityList: [
        {
          label: '残缺',
          value: '残缺',
        },
        {
          label: '基本完整',
          value: '基本完整',
        },
        {
          label: '完整',
          value: '完整',
        },
        {
          label: '严重残缺',
          value: '严重残缺',
        },
      ],
      cStoreStateList: [],
      type: '',
    }
  },
  mounted() {
    this.$axios(this.api.collection.listSelect).then(data => {
      if (data.status) {
        this.typeList = data.data.map(item => {
          return {
            label: item.collectionTypeName,
            value: item.id,
          }
        })
      }
    })
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data.map(item => {
          return {
            label: item.levelName,
            value: item.level,
          }
        })
      }
    })
  },

  methods: {
    init(config, dataListSelect, type) {
      this.dialogVisible = true
      this.type = type
      this.$nextTick(() => {
        this.allCatalogingList = [
          {
            basicName: '藏品名称',
            code: 'collectionName',
            maxLength: '300',
            fixedField: true,//是否是固定字段
            archivesBasicTypeCode: 'text',
            archivesBasicMultiselectList: [],
          },
          {
            basicName: '藏品类型',
            code: 'collectionType',
            fixedField: true,//是否是固定字段
            archivesBasicMultiselectList: this.typeList,
            archivesBasicTypeCode: 'text',
          },
          {
            basicName: '是否鉴定',
            code: 'identified',
            fixedField: true,//是否是固定字段
            archivesBasicMultiselectList: this.identifyList,
            archivesBasicTypeCode: 'text',
          },
          {
            basicName: '级别',
            code: 'collectionLevel',
            fixedField: true,//是否是固定字段
            archivesBasicMultiselectList: this.leaveList,
            archivesBasicTypeCode: 'text',
          },
          {
            basicName: '辅助账编号',
            code: 'helpNum',
            maxLength: '50',
            fixedField: true,//是否是固定字段
            archivesBasicTypeCode: 'text',
            archivesBasicMultiselectList: [],
          },
          {
            basicName: '总账编号',
            code: 'generalNum',
            maxLength: '50',
            fixedField: true,//是否是固定字段
            archivesBasicTypeCode: 'text',
            archivesBasicMultiselectList: [],
          },
          {
            basicName: '完残程度',
            code: 'integrity',
            fixedField: true,//是否是固定字段
            archivesBasicMultiselectList: this.integrityList,
            archivesBasicTypeCode: 'text',
          },
          {
            basicName: '库存状态',
            code: 'cStoreState',
            fixedField: true,//是否是固定字段
            archivesBasicMultiselectList: this.$dictUtils.getDictList('cStore_state'),
            archivesBasicTypeCode: 'text',
          },
          {
            basicName: '完残情况',
            code: 'integrityInfo',
            maxLength: '50',
            fixedField: true,//是否是固定字段
            archivesBasicTypeCode: 'text',
            archivesBasicMultiselectList: [],
          },
          ...config,
        ]
        this.dataListSelect = dataListSelect.map(item => {
          return item.id
        })
      })
    },

    selectOldCataloging() {
      this.inputForm.oldCount = ''
      this.inputForm.newCount = ''
      if (this.inputForm.oldCataloging) {
        let seleData = this.allCatalogingList.filter(item => {
          return item.code == this.inputForm.oldCataloging
        })
        this.selectData = seleData[0]
        console.log(this.selectData)
      } else {
        this.selectData = ''
      }
    },

    // 获取元数据类型设置输入正则
    getNumType(item, num) {
      let regular = /[^\d]/g
      if (item.archivesBasicTypeCode == "double") {
        regular = /[^\d.]/g
      }
      this.$set(this.inputForm, num ? 'newCount' : 'oldCount', this.inputForm[num ? 'newCount' : 'oldCount'].replace(regular, ''))
    },

    // 浮点数保留两位小数
    verifyNum(item, numdasf) {
      console.log(item)
      if (item.archivesBasicTypeCode == "double") {
        let val = this.inputForm[numdasf ? 'newCount' : 'oldCount']
        let pointNum = val.toString().split(".").length - 1
        if (val && !this.validate.isPrice(val) && pointNum <= 1) {
          let num = (val * 1).toFixed(2)
          this.$set(this.inputForm, numdasf ? 'newCount' : 'oldCount', num)
        }
      }
    },

    //替换
    sureData() {
      this.$refs.inputForm.validate((valid) => {
        if (valid) {
          let data = {
            archivesBasicTypeCode: this.selectData.archivesBasicTypeCode,
            basicCode: this.selectData.code,
            ids: this.dataListSelect,
            isSys: this.selectData.fixedField ? '1' : '0',
            replace: this.inputForm.newCount,
            value: this.inputForm.oldCount,
          }
          if (this.type) {
            this.$set(data, 'type', 0)
          }
          this.$axios(this.api.collection.intelligentProcessing, data, 'post').then((res) => {
            if (res.status) {
              if (res.data.error) {
                this.$message.error(`${res.data.success}条成功，${res.data.error}条处理失败，该数据不包含此字段，请选择其他字段进行处理！`)
              }else {
                this.$message.success(`修改成功`)
              }
              this.$emit('refreshData')
              this.handleClose()
            } else {
              this.$message.error(res.msg);
            }
          })
        }
      });
    },

    handleClose() {
      this.$refs.inputForm.resetFields()
      this.selectData = ''
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>

</style>