<template>
    <el-dialog
            title="转总账结果"
            :visible.sync="dialogVisible"
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <div>共提交{{ total }}条数据，<span style="color: yellowgreen">{{ trueNumber }}</span>数据转入总账成功，<span style="color: green">{{ errorNumber }}</span>条转入失败，失败数据如下：</div>
        <el-table
                :data="dataList"
                size="small"
                v-loading="loading"
                ref="multipleTable"
                height="calc(100vh - 295px)"
                class="table"
        >
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
            <el-table-column v-for="(item, index) in config" :key="item.archivesBasicId"
                             :prop="item.code"
                             :label="item.basicName" v-if="item.dataSelect == 0" min-width="120"
                             show-overflow-tooltip>
                <template slot="header" slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName" placement="top-start">
                        <span class="omit">{{item.forName ? item.forName : item.basicName}}</span>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <div>{{ scope.row.collections[item.code] }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="collectionTypeName" label="藏品类型" show-overflow-tooltip></el-table-column>
            <el-table-column prop="collectionLevel" label="级别" show-overflow-tooltip></el-table-column>
        </el-table>
<!--        <div class="text_center">-->
<!--            <el-pagination-->
<!--                    @size-change="sizeChangeHandle"-->
<!--                    @current-change="currentChangeHandle"-->
<!--                    :current-page="pageNo"-->
<!--                    :page-sizes="[10, 20, 50, 100]"-->
<!--                    :page-size="pageSize"-->
<!--                    :total="total"-->
<!--                    background-->
<!--                    layout="prev, pager, next"-->
<!--            >-->
<!--            </el-pagination>-->
<!--        </div>-->
        <div class="text_center" style="margin-top: 20px">
            <el-button size="small" @click="handleClose()">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "ledgeResult",
    data() {
        return {
            id:'',
            config:[],
            dialogVisible: false,
            dataList: [],
            loading: false,
            pageNo: 1,
            pageSize: 10,
            total: 0,
            trueNumber:0,
            errorNumber:0,
        }
    },
    methods: {
        init(id,config) {
            if (id){
                this.id = id
                this.config = config
            }
            this.loading = true
            this.$axios(this.api.collection.auxiliaryAccountLedger,{
                ids:this.id,
                // current: this.pageNo,
                // size: this.pageSize,
            }, 'post').then((res) => {
                if (res.status){
                    this.trueNumber = res.data.trueNumber
                    this.total = parseInt(res.data.total)
                    this.errorNumber = res.data.errorNumber
                    this.dataList = res.data.errorDate
                    this.loading = false
                    this.dialogVisible = true;
                }
            })
        },

        handleClose() {
            this.dialogVisible = false
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageNo = 1;
            this.init();
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.pageNo = val;
            this.init();
        },
    },
}
</script>

<style scoped>

</style>